// Tagus table override
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}

// Mat Card CSS
.mat-mdc-card {
  &.tagus-card {
    box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
    background-color: var(--whiteColor) !important;
    border-radius: 10px !important;
    display: block !important;
    border: none !important;
    padding: 25px;

    .mat-mdc-card-header {
      padding: 0;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;

      .btn {
        width: auto;
        cursor: pointer;
        max-width: unset;
        min-height: unset;
        max-height: unset;
        height: auto !important;
        min-width: unset !important;
        color: var(--paragraphColor);
        padding: 0 0 2px 23px !important;
        font-size: var(--fontSize) !important;

        &::before {
          left: 0;
          top: 50%;
          content: '\f105';
          position: absolute;
          color: var(--blackColor);
          transform: translateY(-50%);
          font: {
            family: flaticon_tagus;
            weight: 700;
            size: 14px;
          }
        }
        .mdc-button__ripple,
        .mat-mdc-button-persistent-ripple,
        .mat-ripple {
          display: none;
        }
      }
      .dot-btn {
        top: 2px;
        width: auto;
        cursor: pointer;
        line-height: 0.01;
        max-width: unset;
        min-height: unset;
        max-height: unset;
        padding: 0 !important;
        height: auto !important;
        font-size: 25px !important;
        min-width: unset !important;
        color: var(--paragraphColor);
        transition: var(--transition);

        &:hover {
          color: var(--mainColor);
        }
        .mdc-button__ripple,
        .mat-mdc-button-persistent-ripple,
        .mat-ripple {
          display: none;
        }
      }
      .sub-title {
        color: var(--paragraphColor);
      }
      .default-btn {
        padding: 14px 25px 14px 46px !important;
        font: {
          weight: 600 !important;
          size: 14px !important;
        }
        .mdc-button__label {
          display: block;
          position: unset;
        }
        i {
          top: 50%;
          left: 22px;
          right: auto;
          margin-top: 0;
          line-height: 1;
          font-size: 18px;
          position: absolute;
          transform: translateY(-50%);
        }
        &.small {
          padding: 11px 20px 11px 39px !important;

          i {
            left: 18px;
            font-size: 16px;
          }
        }
      }
      .search-box {
        width: 280px;

        .input-search {
          background-color: #f5f7fa;
          padding: 0 15px 2px 15px;
          color: var(--blackColor);
          outline: 0 !important;
          border-radius: 5px;
          height: 41px;
          width: 100%;
          border: 0;

          &::placeholder {
            color: #a9a9c8;
            transition: var(--transition);
          }
          &:focus {
            &::placeholder {
              color: transparent;
            }
          }
        }
        button {
          top: 50%;
          padding: 0;
          right: 20px;
          border: none;
          line-height: 1;
          cursor: pointer;
          position: absolute;
          font-size: 22px !important;
          transform: translateY(-50%);
          color: var(--mainColor);
          background-color: transparent;
          transition: var(--transition);

          &:hover {
            color: var(--blackColor);
          }
        }
      }
      &.border-bottom {
        padding-bottom: 20px;
        border-bottom: 1px solid #eef0f7;
      }
      .mat-mdc-card-header-text {
        display: none;
      }
      .info-list {
        .mat-mdc-icon-button {
          .mat-mdc-button-persistent-ripple {
            border-radius: 4px;
          }
        }
      }
    }

    .mat-mdc-card-content {
      padding: 0;

      .tagus-mat-table {
        .mat-mdc-table {
          .mdc-data-table__header-row {
            .mdc-data-table__header-cell {
              text-align: center;
              font-weight: 600;
              overflow: unset;
              border: none;
              padding: {
                top: 16px;
                bottom: 16px;
              }
              .mat-mdc-checkbox {
                .mdc-checkbox {
                  margin-left: -10px;

                  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
                      [data-indeterminate='true']
                    )
                    ~ .mdc-checkbox__background {
                    border-color: #a9a9c8;
                  }
                }
              }
            }
          }
          .mdc-data-table__content {
            .mdc-data-table__row {
              .mdc-data-table__cell {
                overflow: unset;
                padding: 15px 20px;
                text-align: center;
                white-space: nowrap;
                color: var(--paragraphColor);
                border-bottom-color: #f7faff;

                .product-info {
                  color: var(--paragraphColor);
                  font: {
                    weight: 600;
                    family: var(--headingFontFamily);
                  }
                  img {
                    width: 48px;
                    margin-right: 12px;
                    border-radius: 10px;
                  }
                  &:hover {
                    color: var(--mainColor);
                  }
                }
                .badge {
                  font-size: 14px;
                  border-radius: 4px;
                  padding: 4px 13px 5px;
                  color: var(--mainColor);
                  background: rgba(117, 127, 239, 0.1);

                  &.delivered {
                    background: rgba(0, 182, 155, 0.1);
                    color: #00b69b;
                  }
                  &.outOfStock {
                    color: #04dcbf;
                    background: rgba(238, 54, 140, 0.1);
                  }
                }
                .mat-mdc-checkbox {
                  .mdc-checkbox {
                    margin-left: -10px;

                    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
                        [data-indeterminate='true']
                      )
                      ~ .mdc-checkbox__background {
                      border-color: #a9a9c8;
                    }
                  }
                }
                .dot-btn {
                  width: 35px;
                  line-height: 30px;
                  padding: 0 !important;
                  height: 30px !important;
                  color: var(--blackColor);
                  font-size: 18px !important;
                  min-width: unset !important;
                  border-radius: 4px !important;
                  display: inline-block !important;
                  background-color: #f3f6f9 !important;
                }
                .rating {
                  i {
                    line-height: 1;
                    color: #ffbc2b;
                    margin: {
                      left: 2px;
                      right: 2px;
                    }
                  }
                }
                .colors {
                  span {
                    width: 10px;
                    height: 10px;
                    font-size: 0;
                    border-radius: 50%;
                    display: inline-block;
                    border: 1px solid #eeeeee;
                    background-color: var(--whiteColor);
                    margin: {
                      left: 2px;
                      right: 2px;
                    }
                    &.red {
                      background-color: red;
                      border-color: red;
                    }
                    &.blue {
                      background-color: blue;
                      border-color: blue;
                    }
                    &.green {
                      background-color: green;
                      border-color: green;
                    }
                    &.orange {
                      background-color: orange;
                      border-color: orange;
                    }
                    &.black {
                      background-color: black;
                      border-color: black;
                    }
                    &.yellow {
                      background-color: yellow;
                      border-color: yellow;
                    }
                    &.purple {
                      background-color: purple;
                      border-color: purple;
                    }
                    &.brown {
                      background-color: brown;
                      border-color: brown;
                    }
                    &.pink {
                      background-color: pink;
                      border-color: pink;
                    }
                    &.maroon {
                      background-color: maroon;
                      border-color: maroon;
                    }
                    &.violet {
                      background-color: violet;
                      border-color: violet;
                    }
                    &:first-child {
                      margin-left: 0;
                    }
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
              &:last-child {
                .mdc-data-table__cell {
                  border-bottom: 1px solid #f7faff;
                }
              }
            }
          }
          .mat-sort-header-container {
            justify-content: center;

            .mat-sort-header-arrow {
              top: 2px;
            }
          }
        }
        &.recentOrdersTable {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                &:first-child {
                  color: var(--mainColor);
                }
              }
            }
          }
        }
        &.teamMembersListTable {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                padding: {
                  left: 25px;
                  right: 25px;
                }
                &:first-child {
                  color: var(--mainColor);
                }
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: {
                    left: 25px;
                    right: 25px;
                  }
                  a {
                    color: var(--paragraphColor);

                    &:hover {
                      color: var(--mainColor);
                    }
                  }
                  .mdc-checkbox {
                    margin-right: 5px;
                  }
                  .role-info {
                    display: inline-block;
                    padding-left: 28px;

                    i,
                    img {
                      left: 0;
                      top: 50%;
                      line-height: 1;
                      font-size: 17px;
                      position: absolute;
                      transform: translateY(-50%);
                      color: var(--mainColor);
                    }
                  }
                  .user-info {
                    margin-right: 25px;
                    color: var(--blackColor);
                    font: {
                      size: 16px;
                      weight: 600;
                      family: var(--headingFontFamily);
                    }
                    img {
                      margin-right: 12px;
                    }
                    span {
                      margin-top: 4px;
                      font: {
                        size: 15px;
                        family: var(--fontFamily);
                      }
                    }
                  }
                  .badge {
                    background: rgba(0, 182, 155, 0.1);
                    color: #00b69b;

                    &.inactive {
                      color: #04dcbf;
                      background: rgba(238, 54, 140, 0.1);
                    }
                  }
                }
              }
            }
          }
        }
        &.myTasksTable {
          -webkit-overflow-scrolling: touch;
          overflow: {
            x: auto;
            y: hidden;
          }
          .mdc-data-table__header-row {
            .mdc-data-table__header-cell {
              &:first-child {
                color: var(--mainColor);
              }
            }
          }
          .mdc-data-table__content {
            .mdc-data-table__row {
              .mdc-data-table__cell {
                padding: 12px 20px;

                img {
                  width: 30px;
                  height: 30px;
                }
                .badge {
                  color: #00b69b;
                  background: rgba(0, 182, 155, 0.1);

                  &.pending {
                    color: #04dcbf;
                    background: rgba(238, 54, 140, 0.1);
                  }
                  &.completed {
                    color: #757fef;
                    background: rgba(117, 127, 239, 0.1);
                  }
                }
                .name-info {
                  font: {
                    size: 16px;
                    weight: 600;
                    family: var(--headingFontFamily);
                  }
                }
                &:first-child {
                  color: var(--blackColor);
                }
              }
            }
          }
        }
        &.invoiceListsTable {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                &:first-child {
                  color: var(--mainColor);
                }
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  .user-info {
                    .title {
                      margin-left: 12px;

                      h5 {
                        font-size: 16px;
                      }
                      span {
                        margin-top: 5px;
                      }
                    }
                  }
                  .badge {
                    background: rgba(0, 182, 155, 0.1);
                    color: #00b69b;

                    &.pending {
                      color: #04dcbf;
                      background: rgba(238, 54, 140, 0.1);
                    }
                  }
                }
              }
            }
          }
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;

          &:vertical {
            width: 10px;
          }
          &:horizontal {
            height: 10px;
          }
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          border: 2px solid var(--whiteColor);
          background-color: rgba(0, 0, 0, 0.2);
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: var(--whiteColor);
        }
      }
      .kanban-item {
        .dot-btn {
          .mdc-button__ripple,
          .mat-mdc-button-persistent-ripple,
          .mat-ripple {
            display: none;
          }
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
      .product-tabs {
        &.mat-mdc-tab-group {
          display: block;
          margin-top: 25px;

          .mat-mdc-tab-header {
            margin-bottom: 20px;
            border-bottom: 1px solid #f7faff;

            .mat-mdc-tab-label-container {
              .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                  .mat-mdc-tab {
                    height: auto;
                    display: block;
                    min-width: auto;
                    margin-right: 15px;
                    padding: 15px 30px;
                    border-radius: 5px;
                    background-color: #f5f7fa;
                    transition: var(--transition);
                    font: {
                      size: 18px;
                      weight: 600;
                      family: var(--headingFontFamily);
                    }
                    .mdc-tab__text-label {
                      transition: var(--transition);
                      color: var(--blackColor);
                    }
                    .mdc-tab-indicator {
                      display: none;
                    }
                    &:last-child {
                      margin-right: 0;
                    }
                    &.mdc-tab--active {
                      background-color: var(--mainColor);
                      color: var(--whiteColor);

                      .mdc-tab__text-label {
                        color: var(--whiteColor);
                      }
                    }
                  }
                }
              }
            }
          }
          .mat-mdc-tab-body-wrapper {
            .mat-mdc-tab-body {
              overflow: hidden;

              .mat-mdc-tab-body-content {
                overflow: hidden;
              }
            }
          }
        }
      }
      .note-box {
        .mat-mdc-form-field {
          display: block;

          .mat-mdc-form-field-focus-overlay {
            display: none;
          }
        }
        .mdc-text-field--filled {
          &:not(.mdc-text-field--disabled) {
            background-color: transparent;
          }
        }
        .mat-mdc-text-field-wrapper {
          border-radius: 10px;
          padding: 0 15px 0 60px;
          border: 1px solid #e7ebf5;
        }
        .date-input {
          .mat-mdc-text-field-wrapper {
            padding: 0 15px 0 20px;
          }
        }
        .mat-mdc-icon-button {
          &.mat-mdc-button-base {
            padding: 12px 0 12px 0;
            margin-right: -15px;
          }
        }
        .mdc-line-ripple {
          display: none;
        }
        i {
          top: 15.5px;
          left: -38px;
          line-height: 1;
          color: #a9a9c8;
          position: absolute;
          font: {
            size: 25px;
            weight: normal;
          }
        }
        input[type='file'] {
          border: 1px solid #b1b5c3;
          border-radius: 10px;
          padding: 15px 25px;
          cursor: pointer;
          display: block;
          width: 100%;
        }
        .default-btn {
          width: auto;
          max-width: unset;
          min-height: unset;
          max-height: unset;
          height: auto !important;
          color: var(--whiteColor);
          min-width: unset !important;
          padding: 17px 50px !important;
          background-color: var(--mainColor);
          font: {
            family: var(--headingFontFamily) !important;
            weight: 600 !important;
            size: 17px !important;
          }
          .mdc-button__label {
            display: block;
            position: unset;
          }
          &:hover {
            color: var(--whiteColor);
            background-color: var(--aareRiverColor);
          }
        }
      }
    }

    .mat-mdc-checkbox {
      &.mat-accent {
        .mat-mdc-checkbox-ripple {
          display: none;
        }
        .mdc-form-field {
          color: var(--paragraphColor);
        }
      }
    }

    &::after {
      display: none;
    }

    &.bg-main-color {
      background-color: var(--mainColor) !important;
    }
  }
}

// Borderd Card Theme
.card-borderd-theme {
  .mat-mdc-card {
    &.tagus-card {
      box-shadow: var(--borderBoxShadow);
    }
  }
}

// Border Radius Card Theme
.card-border-radius {
  .mat-mdc-card {
    border-radius: 0 !important;

    &.tagus-card {
      border-radius: 0 !important;
    }
  }
}

// Dark Mode
.dark-theme {
  background-color: #0d1015;
  color: var(--whiteColor);

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--whiteColor);
  }
  p {
    color: #828690;
  }
  input {
    color: var(--whiteColor);
    background-color: #181b20;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  a {
    color: var(--whiteColor);

    &:hover {
      color: var(--mainColor);
    }
  }

  // Mat Card
  .mat-mdc-card {
    --mdc-elevated-card-container-color: #14171c;

    .mat-mdc-card-subtitle {
      color: rgba(255, 255, 255, 0.54);
    }
    &.tagus-card {
      box-shadow: unset;
      background-color: #14171c !important;

      .mat-mdc-card-header {
        .btn {
          color: #828690;

          &::before {
            color: #828690;
          }
        }
        .dot-btn {
          color: #828690;

          &:hover {
            color: var(--mainColor);
          }
        }
        .sub-title {
          color: #828690;
        }
        .search-box {
          .input-search {
            background-color: #1e2227;
            color: var(--whiteColor);

            &::placeholder {
              color: #828690;
            }
            &:focus {
              &::placeholder {
                color: transparent;
              }
            }
          }
          button {
            &:hover {
              color: var(--whiteColor);
            }
          }
        }
        &.border-bottom {
          border-bottom-color: #1f2226;
        }
      }
      .mat-mdc-card-content {
        .tagus-mat-table {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                .mat-mdc-checkbox {
                  .mdc-checkbox {
                    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
                        [data-indeterminate='true']
                      )
                      ~ .mdc-checkbox__background {
                      border-color: #a9a9c8;
                    }
                  }
                }
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  color: #828690;
                  border-bottom-color: #1f2226;

                  .product-info {
                    color: #828690;

                    &:hover {
                      color: var(--mainColor);
                    }
                  }
                  .mat-mdc-checkbox {
                    .mdc-checkbox {
                      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
                          [data-indeterminate='true']
                        )
                        ~ .mdc-checkbox__background {
                        border-color: #a9a9c8;
                      }
                    }
                  }
                  .dot-btn {
                    color: var(--whiteColor);
                    background-color: #1d2024 !important;
                  }
                }
                &:last-child {
                  .mdc-data-table__cell {
                    border-bottom-color: #1f2226;
                  }
                }
              }
            }
          }
          &.recentOrdersTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  &:first-child {
                    color: var(--mainColor);
                  }
                }
              }
            }
          }
          &.teamMembersListTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  &:first-child {
                    color: var(--mainColor);
                  }
                }
              }
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    a {
                      color: #828690;

                      &:hover {
                        color: var(--mainColor);
                      }
                    }
                    .user-info {
                      color: var(--whiteColor);
                    }
                  }
                }
              }
            }
          }
          &.myTasksTable {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                &:first-child {
                  color: var(--mainColor);
                }
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  &:first-child {
                    color: var(--whiteColor);
                  }
                }
              }
            }
          }
          &.invoiceListsTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  &:first-child {
                    color: var(--mainColor);
                  }
                }
              }
            }
          }
          &::-webkit-scrollbar-thumb {
            border-color: #1f2226;
            background-color: rgba(255, 255, 255, 0.2);
          }
          &::-webkit-scrollbar-track {
            background-color: #1f2226;
          }
        }
        .product-tabs {
          &.mat-mdc-tab-group {
            .mat-mdc-tab-header {
              border-bottom-color: #1f2226;

              .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                  .mat-mdc-tab-labels {
                    .mat-mdc-tab {
                      background-color: #1d2024;

                      .mdc-tab__text-label {
                        color: var(--whiteColor);
                      }
                      &.mdc-tab--active {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);

                        .mdc-tab__text-label {
                          color: var(--whiteColor);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .note-box {
          .mat-mdc-text-field-wrapper {
            border-color: #1f2226;
          }
          i {
            color: #828690;
          }
          input[type='file'] {
            border-color: #1f2226;
            background-color: #14171c;
          }
        }
      }
      .mat-mdc-checkbox {
        &.mat-accent {
          .mdc-form-field {
            color: #828690;
          }
        }
      }
    }
    &.bg-main-color {
      background-color: var(--mainColor) !important;
    }
  }

  // Default BTN
  .default-btn {
    &:hover {
      color: var(--whiteColor);
    }
  }
}

// RTL CSS
.rtl-enabled {
  direction: rtl;
  text-align: right;

  // Default BTN
  .default-btn {
    padding: {
      left: 31px;
      right: 15px;
    }
    i {
      right: auto;
      left: 15px;
    }
  }
}

// Keyframes CSS
@keyframes ripple {
  0%,
  35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
  .h1,
  h1 {
    font-size: 36px;
  }
  .h2,
  h2 {
    font-size: 28px;
  }
  .h3,
  h3 {
    font-size: 24px;
  }
  .h4,
  h4 {
    font-size: 20px;
  }
  .h5,
  h5 {
    font-size: 16px;
  }
  .h6,
  h6 {
    font-size: 12px;
  }
  button {
    font-size: 14px !important;
  }

  // Default BTN
  .default-btn {
    font-size: 13px;
    padding: 11px 31px 11px 15px;

    i {
      margin-top: 2px;
      right: 15px;
    }
  }

  // Menu Dropdown CSS
  .menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        border-radius: 5px 5px 0 0;
        width: 250px;

        .menu-header {
          border-radius: 5px 5px 0 0;
          padding: 12px;

          h5 {
            font-size: 15px;
          }
          button {
            font-size: 13px;
          }
        }
        .menu-body {
          li {
            padding: 10px 28px 10px 12px;

            img {
              width: 45px;
            }
            .title {
              margin-left: 12px;

              h6 {
                font-size: 13.5px;
              }
              span {
                font-size: 12px;
                padding-left: 16px;

                i {
                  margin-top: 1px;
                }
              }
            }
            .close-btn {
              right: 10px;
            }
            .icon {
              font-size: 25px;
            }
          }
          .dropdown-item {
            padding: {
              top: 5px;
              bottom: 5px;
            }
            span {
              margin-top: 3px;
              font-size: 13px;
            }
          }
          .d-flex {
            &.flex-wrap {
              padding: 5px;
            }
          }
        }
        .menu-footer {
          padding: {
            top: 11px;
            bottom: 12px;
          }
          a {
            font-size: 13px;
            padding-right: 15px;
          }
        }
      }
    }
  }

  // RTL CSS
  .rtl-enabled {
    // Default BTN
    .default-btn {
      padding: {
        left: 11px;
        right: 15px;
      }
      i {
        right: auto;
        left: 15px;
      }
    }

    // Mat Card CSS
    .mat-mdc-card {
      &.tagus-card {
        .mat-mdc-card-header {
          .btn {
            padding: {
              left: 0 !important;
              right: 18px !important;
            }
          }
          .default-btn {
            padding: {
              left: 18px !important;
              right: 35px !important;
            }
            i {
              left: auto;
              right: 15px;
            }
          }
        }
        .mat-mdc-card-content {
          .tagus-mat-table {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .product-info {
                      margin-left: 0;
                      padding: {
                        left: 35px;
                        right: 0;
                      }
                      img {
                        margin: {
                          right: 0;
                          left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.teamMembersListTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .role-info {
                        padding: {
                          left: 0;
                          right: 27px;
                        }
                      }
                      .user-info {
                        margin-left: 0;
                        padding: {
                          left: 35px;
                          right: 0;
                        }
                        img {
                          margin: {
                            right: 0;
                            left: 12px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.invoiceListsTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .user-info {
                        padding: {
                          left: 40px;
                          right: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .product-tabs {
            &.mat-mdc-tab-group {
              .mat-mdc-tab-header {
                .mat-mdc-tab-label-container {
                  .mat-mdc-tab-list {
                    .mat-mdc-tab-labels {
                      .mat-mdc-tab {
                        margin: {
                          right: 0;
                          left: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .note-box {
            .mat-mdc-text-field-wrapper {
              padding: {
                left: 15px;
                right: 45px;
              }
            }
            i {
              left: auto;
              right: -30px;
            }
          }
        }
        &.enrolled-created-box {
          .mat-mdc-tab-group {
            .mat-mdc-tab-header {
              .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                  .mat-mdc-tab-labels {
                    .mat-mdc-tab {
                      margin: {
                        right: 0;
                        left: 10px;
                      }
                    }
                  }
                }
              }
            }
            .mat-mdc-tab-body-wrapper {
              .mat-mdc-tab-body {
                .mat-mdc-tab-body-content {
                  .mat-elevation-z8 {
                    .mat-mdc-table {
                      .mdc-data-table__content {
                        .mdc-data-table__row {
                          .mdc-data-table__cell {
                            .course-info {
                              margin-left: 0;

                              img {
                                margin-left: 0;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .course-details-desc {
          .mat-accordion {
            .mat-expansion-indicator {
              right: auto;
              left: 15px;
            }
            .mat-expansion-panel-body {
              .content-list {
                li {
                  span {
                    &:nth-child(1) {
                      padding: {
                        left: 0;
                        right: 19px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.create-project-card {
          form {
            .mat-mdc-text-field-wrapper {
              padding: {
                left: 15px;
                right: 45px;
              }
            }
            i {
              right: -30px;
              left: auto;
            }
          }
        }
      }
    }

    // Col
    .col {
      margin: {
        right: 0 !important;
        left: 0 !important;
      }
      &:last-child {
        margin-left: 0 !important;

        .col {
          margin: {
            right: 0 !important;
            left: 0 !important;
          }
          &:last-child {
            margin-left: 0 !important;

            .col {
              margin: {
                right: 0 !important;
                left: 0 !important;
              }
              &:last-child {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
  button {
    font-size: 14px !important;
  }

  // Menu Dropdown CSS
  .menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        .menu-header {
          h5 {
            font-size: 16px;
          }
          button {
            font-size: 13px;
          }
        }
        .menu-body {
          li {
            .title {
              margin-left: 12px;

              h6 {
                font-size: 14px;
              }
              span {
                i {
                  margin-top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      padding: 20px;

      .mat-mdc-card-header {
        margin-bottom: 20px;

        .btn {
          padding: 0 0 2px 23px !important;
          font-size: 14px !important;

          &::before {
            font: {
              size: 14px;
            }
          }
        }
        &.border-bottom {
          padding-bottom: 15px;
        }
      }
      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;
          overflow: {
            x: auto;
            y: hidden;
          }
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                padding: 15px 20px;
                white-space: nowrap;

                &::after {
                  height: 15px;
                }
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: 15px 20px;
                  white-space: nowrap;

                  .product-info {
                    margin-right: 0;
                    padding-right: 35px;

                    img {
                      width: 45px;
                    }
                  }
                }
              }
            }
          }
          &.teamMembersListTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  padding: {
                    left: 20px;
                    right: 20px;
                  }
                }
              }
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    padding: {
                      left: 20px;
                      right: 20px;
                    }
                    .user-info {
                      padding-right: 35px;
                      margin-right: 0;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
          &.invoiceListsTable {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .user-info {
                      padding-right: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.enrolled-created-box {
        .mat-mdc-tab-group {
          .mat-mdc-tab-header {
            .mat-mdc-tab-label-container {
              .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                  .mat-mdc-tab {
                    padding: 14px 25px;
                    font-size: 16px;
                  }
                }
              }
            }
          }
          .mat-mdc-tab-body-wrapper {
            .mat-mdc-tab-body {
              .mat-mdc-tab-body-content {
                .mat-elevation-z8 {
                  .mat-mdc-table {
                    .mdc-data-table__content {
                      .mdc-data-table__row {
                        .mdc-data-table__cell {
                          .course-info {
                            margin-right: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // RTL CSS
  .rtl-enabled {
    // Mat Card CSS
    .mat-mdc-card {
      &.tagus-card {
        .mat-mdc-card-header {
          .btn {
            padding: {
              left: 0 !important;
              right: 23px !important;
            }
          }
        }
        .mat-mdc-card-content {
          .tagus-mat-table {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .product-info {
                      margin-left: 0;
                      padding: {
                        left: 35px;
                        right: 0;
                      }
                    }
                  }
                }
              }
            }
            &.teamMembersListTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .user-info {
                        margin-left: 0;
                        padding: {
                          right: 0;
                          left: 35px;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.invoiceListsTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .user-info {
                        padding: {
                          right: 0;
                          left: 40px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.enrolled-created-box {
          .mat-mdc-tab-group {
            .mat-mdc-tab-body-wrapper {
              .mat-mdc-tab-body {
                .mat-mdc-tab-body-content {
                  .mat-elevation-z8 {
                    .mat-mdc-table {
                      .mdc-data-table__content {
                        .mdc-data-table__row {
                          .mdc-data-table__cell {
                            .course-info {
                              margin-left: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Col
    .col {
      margin: {
        right: 0 !important;
        left: 0 !important;
      }
      &:last-child {
        margin-left: 0 !important;

        .col {
          margin: {
            right: 0 !important;
            left: 0 !important;
          }
          &:last-child {
            margin-left: 0 !important;

            .col {
              margin: {
                right: 0 !important;
                left: 0 !important;
              }
              &:last-child {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;
          overflow: {
            x: auto;
            y: hidden;
          }
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                white-space: nowrap;
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  white-space: nowrap;

                  .product-info {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // RTL CSS
  .rtl-enabled {
    // Mat Card CSS
    .mat-mdc-card {
      &.tagus-card {
        .mat-mdc-card-header {
          .btn {
            padding: {
              left: 0 !important;
              right: 23px !important;
            }
          }
        }
        .mat-mdc-card-content {
          .tagus-mat-table {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .product-info {
                      margin-left: 0;
                      padding: {
                        right: 0;
                        left: 35px;
                      }
                    }
                  }
                }
              }
            }
            &.teamMembersListTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .user-info {
                        margin: {
                          right: 0;
                          left: 35px;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.invoiceListsTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .user-info {
                        padding: {
                          right: 0;
                          left: 40px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.enrolled-created-box {
          .mat-mdc-tab-group {
            .mat-mdc-tab-body-wrapper {
              .mat-mdc-tab-body {
                .mat-mdc-tab-body-content {
                  .mat-elevation-z8 {
                    .mat-mdc-table {
                      .mdc-data-table__content {
                        .mdc-data-table__row {
                          .mdc-data-table__cell {
                            .course-info {
                              margin-left: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Col
    .col {
      margin: {
        right: 0 !important;
        left: 0 !important;
      }
      &:last-child {
        margin-left: 0 !important;

        .col {
          margin: {
            right: 0 !important;
            left: 0 !important;
          }
          &:last-child {
            margin-left: 0 !important;

            .col {
              margin: {
                right: 0 !important;
                left: 0 !important;
              }
              &:last-child {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;
          overflow: {
            x: auto;
            y: hidden;
          }
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                white-space: nowrap;
              }
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  white-space: nowrap;

                  .product-info {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // RTL CSS
  .rtl-enabled {
    // Mat Card CSS
    .mat-mdc-card {
      &.tagus-card {
        .mat-mdc-card-content {
          .tagus-mat-table {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .product-info {
                      padding: {
                        right: 0;
                        left: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {
  // Default BTN
  .default-btn {
    font-size: 14px;
    padding: 11px 42px 11px 22px;

    i {
      right: 22px;
      margin-top: 1px;
    }
  }

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      .mat-mdc-card-content {
        .tagus-mat-table {
          .mat-mdc-table {
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  .product-info {
                    margin-right: -80px;
                    padding-right: 0;
                  }
                }
              }
            }
          }
          &.teamMembersListTable {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .user-info {
                      margin-right: -80px;
                      padding-right: 0;
                    }
                  }
                }
              }
            }
          }
          &.myTasksTable {
            overflow: {
              x: hidden;
              y: hidden;
            }
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: 15px 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  // RTL CSS
  .rtl-enabled {
    // Default BTN
    .default-btn {
      padding: {
        left: 42px;
        right: 22px;
      }
      i {
        right: auto;
        left: 22px;
      }
    }

    // Mat Card CSS
    .mat-mdc-card {
      &.tagus-card {
        .mat-mdc-card-content {
          .tagus-mat-table {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .product-info {
                      padding-left: 0;
                      margin: {
                        right: 0;
                        left: -80px;
                      }
                    }
                  }
                }
              }
            }
            &.teamMembersListTable {
              .mat-mdc-table {
                .mdc-data-table__content {
                  .mdc-data-table__row {
                    .mdc-data-table__cell {
                      .user-info {
                        margin: {
                          right: 0;
                          left: -80px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
